<template>
    <yandex-map :coords="coords" :zoom="10" class="map" @map-was-initialized="mapInstance">
        <template v-for="(point, i) in points">
          <ymap-marker
              :key="new Date().getTime() + i"
              :coords="point.coords"
              :hint-content="`${point.courier_name ? point.courier_name : 'Не назначен'}<br />${point.address}`"
              :marker-id="point.order_id"
              :options="{
                  iconCaption: '',
                  iconContent: '' ,
                  preset: point.active ? 'islands#redIcon' : point.courier_id ? presets[point.courier_color] : presets['#B3B3B3']
              }"
              :properties="{
                  iconContent: point.order,
                  iconCaption: point.content
              }"
              marker-type="Placemark"
          />
          </template>
    </yandex-map>
</template>

<script>
import { mapState } from 'vuex';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import { loadYmap } from 'vue-yandex-maps';
import { settings } from '@/libs/yandex-maps-settings';
import { presets as colors } from '@/config/staff/index';
import { AuthNameSpace } from '@/store/modules/auth/types';
import { getCoords } from '@/libs/yandex-maps-controller';

export default {
  name: 'Map',
  components: {
    yandexMap,
    ymapMarker,
  },
  props: {
    array: {
      type: Array,
      default: () => ([]),
    },
  },
  data: function() {
    return {
      colors,
      base_coords: [55.755819, 37.617644],
    };
  },
  computed: {
    ...mapState(AuthNameSpace, {
      cities: 'cities',
      active_city: 'city_id',
    }),
    coords() {
      return this.base_coords
    },
    points() {
      return this.array.map(p => ({
        ...p,
      }));
    },
    presets() {
      return colors
    },
  },
  async created() {
    await loadYmap(settings)
    await this.getBaseCoord(this.active_city)
  },
  methods: {
    mapInstance(instance) {
      this.$emit('map-instance', instance)
    },
    getCoords(city) {
      return getCoords(city)
    },
    async getBaseCoord(location_id) {
      const city = this.cities.find(city => city.id === location_id) ? this.cities.find(city => city.id === location_id).name : ''
      this.base_coords = Object.values(await this.getCoords(city))
    },
  },
  watch: {
    async active_city(city_id) {
      await this.getBaseCoord(city_id)
      await loadYmap(settings)
    },
  },
}
</script>

<style lang="scss" scoped>
#order-map {
  .map {
    height: 100%;
  }
}
</style>
