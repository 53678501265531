<template>
  <section class="d-flex flex-column map-menu__wrapper">
    <b-card class="map-menu__filters">
      <b-form-checkbox
        v-if="isRouteCreate"
        v-model="filter.courier_appointed"
        name="check-button"
        switch
        class="mb-1"
        @change="onMapOrders"
      >
        {{ filter.courier_appointed ? 'Назначенные и не назначенные' : 'Только не назначеные' }}
      </b-form-checkbox>
      <b-form-datepicker
        class="mb-1"
        v-bind="{
            value: filter.date_from,
            'date-format-options': { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' },
            disabled: (filter.courier_id && !isRouteCreate),
            'start-weekday': 1,
            locale: 'ru',
            labelHelp: '',
          }"
        :style="(filter.courier_id && !isRouteCreate) ? 'opacity: 0.5' : ''"
        @input="onDateSelect"
      />
      <div class="row g-2 d-flex">
        <div class="col mb-1">
          <b-form-select
            v-model="filter.status"
            v-bind="{
                clearable: false,
                options: statusCollection,
                disabled: (filter.courier_id && !isRouteCreate),
                size: 'md',
              }"
            @input="onFilter"
            style="min-width: 150px;"
          ></b-form-select>
        </div>
        <div class="col">
          <b-form-select
            v-model="filter.courier_id"
            v-bind="{
                clearable: false,
                options: staffOptions,
                size: 'md',
              }"
            @input="onFilter"
            style="min-width: 150px;"
          ></b-form-select>
        </div>
      </div>
    </b-card>

    <b-card
      class="app-map__sidemenu app-map__sidemenu_70"
      :class="[filter.courier_id && !isRouteCreate ? 'app-map__sidemenu_60' : '', filter.courier_id && isRouteCreate ? 'app-map__sidemenu_50' : '']"
    >
      <template v-if="loading">
        <div class="d-flex justify-content-center">
          <div
            class="spinner-border text-secondary mauto"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </template>
      <template v-if="!loading && array.length">
        <b-list-group class="order_list">
          <b-list-group-item
            v-for="(point, index) in array"
            :key="index"
            @mouseenter="pointMouseEnter(point)"
            @mouseleave="pointMouseLeave(point)"
            @click.self="onSetCourierToOrder(index)"
            class="d-flex justify-content-between align-content-center"
          >
            <section
              @click="onSetCourierToOrder(index)"
              class="point-info"
            >
              <div>{{ point.content }}</div>
              <div class="point-address">{{ point.address }}</div>
            </section>
            <template v-if="showBage({ point })">
              <section>
                <div
                  class="app-map__route-order"
                  :style="`background-color: ${array[index].courier_color}`"
                >

                  <input
                    type="number"
                    @keydown.space.prevent
                    @input="onSetOrder({ $event, index, order_id: point.order_id })"
                    :value="point.order"
                    :readonly="!isRouteCreate"
                  >
                </div>
              </section>
            </template>
          </b-list-group-item>
        </b-list-group>
      </template>
      <template v-if="!loading && !array.length && !filter.courier_id && !isRouteCreate">
        <p class="text-center">Маршрутов по заданным параметрам не найдено</p>
      </template>
      <template v-if="!loading && !array.length && filter.courier_id && !isRouteCreate">
        <p class="text-center">Для этого курьера маршрут не создан</p>
      </template>
      <template v-if="!loading && !array.length && isRouteCreate">
        <p class="text-center">Маршрутов не найдено</p>
      </template>
    </b-card>
    <div class="d-inline-flex justify-content-center mb-2 map-menu__buttons flex-grow-1">
      <template v-if="!loading && hasRoute && filter.courier_id && !isRouteCreate">
        <b-button
          style="height: 50px;"
          variant="danger"
          @click="onEditRoute"
        >Редактировать маршрут</b-button>
      </template>
      <template v-if="!loading && !hasRoute && filter.courier_id && !isRouteCreate">
        <b-button
          style="height: 50px;"
          variant="danger"
          @click="onAddRoute"
        >Создать маршрут</b-button>
      </template>
      <template v-if="!loading && isRouteCreate">
        <div class="row d-flex">
          <div class="col-6">
            <b-button
              variant="danger"
              @click="onCansel"
            >Отменить</b-button>
          </div>
          <div class="col-6">
            <b-button
              variant="success"
              @click="onSaveRoute"
              :disabled="userData.role === 'florist' && userData.role !== 'courier'"
            >Сохранить</b-button>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { OrderNameSpace, OrderActionTypes } from '@/store/modules/order/types'
import { StaffNameSpace, StaffActionTypes } from '@/store/modules/staff/types'
import { MapNameSpace, MapActionTypes } from '@/store/modules/map/types'
import { RootMutationTypes } from '@/store/types'
import { AuthNameSpace } from '@/store/modules/auth/types'
import { orderStatusCollection } from '@/config/shared'
import { CityNameSpace, CityActionTypes } from '@/store/modules/city/types'
import { colors } from '@/config/staff/index'
import { throttle } from 'lodash'
import { duration } from '@/config'
import { getCoords } from '@/libs/yandex-maps-controller'
import { loadYmap } from 'vue-yandex-maps'
import { settings } from '@/libs/yandex-maps-settings'
import { fromUnixTimeStamp } from '@/utils/time-format'

export default {
  name: 'OrderMap',
  props: ['instance'],
  data: function() {
    return {
      orderStatusCollection,
      filter: {
        status: null,
        courier_id: null,
        current_courier_id: null,
        date_from: new Date(),
        courier_appointed: false,
      },
      array: [],
      showRouteBage: false,
      hasRoute: false,
      loading: false,
      isRouteCreate: false,
      colors,
      pageSize: 20,
    }
  },
  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
      active_city: 'city_id',
    }),
    ...mapState(OrderNameSpace, {
      orders: 'orders',
      order: 'order',
    }),
    ...mapState(StaffNameSpace, {
      staff: 'staffCollection',
    }),
    ...mapState(CityNameSpace, {
      cities: 'cities',
    }),
    ...mapState(MapNameSpace, {
      mapOrders: 'mapOrders',
      itemCount: 'itemCount',
    }),
    mapInstanceRef() {
      return this.instance
    },
    statusCollection() {
      const result = this.orderStatusCollection.filter(item => {
        return item.value === 1 || item.value === 2
      })

      result.unshift({ value: null, text: 'Все' })
      return result
    },
    citiesCollection() {
      const currentCity = this.cities.find(city => {
        return city.id === this.active_city
      })
                ? this.cities.find(city => {
                  return city.id === this.active_city
                })
                : { name: '' }
      return currentCity
    },
    staffOptions() {
      const couriers = this.staff.filter(item => {
        return item.role === 'courier' && item.city === this.citiesCollection.name
      })
      const result = couriers.map(item => {
        return { value: item.id, text: item.full_name, id: item.id, color: item.color }
      })

      result.unshift({ value: null, text: 'Все курьеры' })
      return result
    },
    courierAppointed() {
      return this.filter.courier_appointed ? 1 : 0
    },
  },
  async created() {
    await loadYmap(settings)
    await this.onMapOrders()
  },
  async mounted() {
    this.menuHeightSetup()
    await this.loadStaff({ page: 1, page_size: 100 })
    await this.loadCities({ page: 1, page_size: 100 })
  },
  methods: {
    ...mapActions(OrderNameSpace, {
      [OrderActionTypes.LoadOrderCollection]: OrderActionTypes.LoadOrderCollection,
      [OrderActionTypes.LoadOrder]: OrderActionTypes.LoadOrder,
    }),
    ...mapActions(StaffNameSpace, {
      [StaffActionTypes.LoadStaffCollection]: StaffActionTypes.LoadStaffCollection,
    }),
    ...mapActions(CityNameSpace, {
      [CityActionTypes.LoadCityCollection]: CityActionTypes.LoadCityCollection,
    }),
    ...mapActions(MapNameSpace, {
      [MapActionTypes.LoadMapOrdersCollection]: MapActionTypes.LoadMapOrdersCollection,
      [MapActionTypes.SetOrderToDelivery]: MapActionTypes.SetOrderToDelivery,
      [MapActionTypes.SetCourierToOrder]: MapActionTypes.SetCourierToOrder,
      [MapActionTypes.DeleteCourierFromOrder]: MapActionTypes.DeleteCourierFromOrder,
    }),
    ...mapMutations({
      [RootMutationTypes.SetErrorMessage]: RootMutationTypes.SetErrorMessage,
    }),
    loadCollection: throttle(async function(
        { page,
          page_size,
          status = this.filter.status,
          courier_id = this.isRouteCreate ? null : this.filter.courier_id,
          courier_appointed = this.courierAppointed,
          date_from = this.filter.date_from,
          date_to = this.filter.date_from,
          default_sort_by_order = this.filter.courier_id ? 1 : null,
        }) {
      await this[OrderActionTypes.LoadOrderCollection]({
        page,
        page_size,
        status,
        courier_id,
        courier_appointed,
        date_from,
        date_to,
        default_sort_by_order,
        time: false,
      })
    }, duration),
    loadStaff: throttle(async function({ page, page_size }) {
      await this[StaffActionTypes.LoadStaffCollection]({ page, page_size })
    }, duration),
    loadCities: throttle(async function({ page, page_size }) {
      await this[CityActionTypes.LoadCityCollection]({ page, page_size })
    }, duration),
    loadMapOrders: throttle(async function(
        { page,
          page_size,
          status = this.filter.status,
          courier_id = this.isRouteCreate ? null : this.filter.courier_id,
          courier_appointed = this.courierAppointed,
          date_from = this.filter.date_from,
          date_to = this.filter.date_from,
          default_sort_by_order = this.filter.courier_id ? 1 : null,
        }) {
      await this[MapActionTypes.LoadMapOrdersCollection]({
        page,
        page_size,
        status,
        courier_id,
        courier_appointed,
        date_from,
        date_to,
        default_sort_by_order,
      })
    }, duration),

    async onMapOrders() {
      // Загрузка заказов и преобразование адресов в координаты и установка меток на карту
      this.loading = true
      await this.loadMapOrders({ page: 1, page_size: this.pageSize })
      if (this.itemCount > this.pageSize) {
        await this.loadMapOrders({ page: 1, page_size: this.itemCount })
      }
      await this.setArrayOfOrders('mapOrders')
      this.setUI(this.array)
      this.loading = false
    },

    async setArrayOfOrders(source) {
      // Преобразование адресов в координаты. Создание массива точек
      this.array = []
      this.mapInstanceRef.geoObjects.removeAll()

      const resultArray = []
      const promises = []
      if (this[source]) {
        this[source].forEach(item => {
          promises.push(getCoords(item.delivery_address)) // Геокодер Яндекса - адрес -> lat, lan
        })

        return Promise.all(promises).then(result => {
          this[source].forEach((item, index) => {
            let timeFrom = ''
            let timeTo = ''

            if (item.delivery_interval_from) {
              timeFrom = fromUnixTimeStamp(item.delivery_interval_from).split(':')
              timeFrom = timeFrom.splice(0, 2)
              timeFrom = timeFrom.join(':')
            }

            if (item.delivery_interval_to) {
              timeTo = fromUnixTimeStamp(item.delivery_interval_to).split(':')
              timeTo = timeTo.splice(0, 2)
              timeTo = timeTo.join(':')
            }

            resultArray.push({
              coords: [result[index]['1'], result[index]['2']],
              content: `${timeFrom} - ${timeTo}`,
              address: item.delivery_address,
              status: item.status,
              courier_name: item.courier_full_name,
              courier_id: item.courier_id,
              order_id: item.order_id || item.id,
              courier_color: item.courier_color,
              order: item.order ? item.order : '',
              static: {
                courier_id: item.courier_id,
                courier_color: item.courier_color,
                order: item.order ? item.order : '',
              },
            })
          })
          this.array = resultArray
        })
      } else this.array = []
    },

    setUI({ length }) {
      // Отображение кнопок (изменить маршрут, сохранить и т.д.)
      if (this.filter.courier_id && length) {
        this.hasRoute = true
      } else if (this.filter.courier_id && !length) {
        this.hasRoute = false
      } else if (!this.filter.courier_id) {
        this.hasRoute = false
      }
    },

    showBage({ point }) {
      // Отображение кружка
      if (point.courier_id) return true
    },

    onSetOrder({ $event, index, order_id }) {
      // Установка очереди заказа в маршруте (через input)
      this.array[index].order = $event.target.value
      this.array[index].order_id = order_id
      this.array[index].toEdit = true
    },

    async onFilter() {
      if (!this.filter.courier_id && !this.filter.status) {
        this.isRouteCreate = false
        this.filter.courier_appointed = false
      }
      if (this.filter.courier_id) this.filter.courier_appointed = true
      await this.onMapOrders()
    },

    async onAddRoute() {
      // Режим добавления заказа к маршруту курьера
      this.filter.status = null
      this.loading = true
      await this.loadMapOrders({ page: 1, page_size: 100, courier_id: null })
      await this.setArrayOfOrders('mapOrders')
      this.setUI(this.array)
      this.isRouteCreate = true
      if (!this.filter.courier_appointed) this.filter.courier_appointed = true
      this.loading = false
    },
    onSetCourierToOrder(index) {
      // Назначить курьера на заказ
      if (this.isRouteCreate) {
        if (this.array[index].courier_id !== this.filter.courier_id) {
          // Назначить заказ на выбранного курьера
          this.array[index].courier_id = this.filter.courier_id
          this.array[index].courier_color = this.staffOptions.find(item => item.id === this.filter.courier_id).color
          this.array[index].toEdit = true
        } else {
          if (this.array[index].static.courier_id && this.array[index].static.courier_id !== this.filter.courier_id) {
            // Снять заказ с выбранного курьера и вернуть значения по умолчанию если они были
            this.array[index].courier_id = this.array[index].static.courier_id
            this.array[index].courier_color = this.array[index].static.courier_color
            this.array[index].toEdit = false // Запрос на редактирование отправлен не будет
          } else {
            // Снять заказ с выбранного курьера
            this.array[index].courier_color = '#B3B3B3'
            this.array[index].courier_id = ''
            this.array[index].order = ''
            this.array[index].toEdit = true
          }
        }
      }
    },
    async onSaveRoute() {
      // Сохранение нового маршрута
      let validationArray = this.array.filter(item => {
        return item.courier_id === this.filter.courier_id
      })
      validationArray = validationArray.map(item => +item.order)
      // Проверка на дубликаты order
      if (new Set(validationArray).size !== validationArray.length) {
        this[RootMutationTypes.SetErrorMessage]({ message: 'Порядок некоторых точек маршрута повторяется.', type: 'error' })
      } else {
        if (!this.filter.courier_appointed) this.filter.courier_appointed = true
        const promises = []
        const promisesToDelete = []
        // Курьер на заказ
        this.array.forEach(item => {
          if (item.toEdit) {
            const { order_id, courier_id } = item
            if (courier_id) {
              promises.push(this[MapActionTypes.SetCourierToOrder]({ id: order_id, courier_id: +courier_id }))
            } else {
              promisesToDelete.push(this[MapActionTypes.DeleteCourierFromOrder]({ id: order_id, courier_id: +this.filter.courier_id }))
            }
          }
        })

        // Порядок выполнения заказа
        this.array.forEach(item => {
          if (item.toEdit) {
            const { order_id, order } = item
            promises.push(this[MapActionTypes.SetOrderToDelivery]({ id: order_id, order: +order }))
          }
        })

        if (promises.length) {
          return Promise.all(promises).then(async () => {
            this.isRouteCreate = false
            await this.onMapOrders()
          })
        }
        if (promisesToDelete.length) {
          return Promise.all(promisesToDelete).then(async () => {
            this.isRouteCreate = false
            await this.onMapOrders()
          })
        }
      }
    },
    onCansel() {
      // Отмена создания маршрута
      this.isRouteCreate = false

      this.filter = {
        courier_id: null,
        status: null,
        date_from: this.filter.date_from,
        courier_appointed: false,
      }
    },
    onEditRoute() {
      this.onAddRoute()
    },
    onDateSelect(date) {
      this.filter.date_from = new Date(date)
      this.onFilter()
    },
    pointMouseEnter(point) {
      this.$set(point, 'active', true)
    },
    pointMouseLeave(point) {
      this.$set(point, 'active', false)
    },
    menuHeightSetup() {
      if (window.innerWidth > 1024) {
        const wrapper = document.querySelector('.content-wrapper').offsetHeight
        const menu = document.querySelector('.app-map-menu-section')
        menu.style.maxHeight = wrapper - 5 + 'px'
      }
    },
  },
  watch: {
    array: {
      deep: true,
      handler() {
        this.$emit('update:array', { array: this.array })
      },
    },
    async active_city() {
      await loadYmap(settings)
      await this.onMapOrders()
    },
  },
}
</script>

<style lang="scss">
$width: 35px;
$height: 35px;
._border {
    border: 1px solid red;
}
.map-menu__wrapper {
    height: 100%;
}
.map-menu__filters {
    max-height: 300px;
}
.map-menu__buttons {
    max-height: 150px;
}
.order-map {
    .app-content {
        max-height: 100%;
        min-height: 100%;
        height: 100%;
        overflow: hidden;
        @media screen and (max-width: 980px) {
            overflow: auto;
        }
    }

    .content-wrapper {
        height: 100%;
    }

    .content-body {
        height: 100%;
    }
}

.app-map__sidemenu {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(#fff, 0.2);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(#000000, 0.8);
    }
    .point-info {
        position: relative;
        padding-right: 1rem;
        @media screen and (max-width: 980px) {
            font-size: 0.9rem;
        }
        .point-address {
            max-width: 100%;
            white-space: pre-wrap;
        }
    }
    .app-map__route-order {
        position: relative;
        width: $width;
        height: $height;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        input {
            position: relative;
            width: $width;
            height: $height;
            background-color: transparent;
            border-radius: 50%;
            border: none;
            outline: none;
            text-align: center;
            font-weight: 600;
            color: #000;
        }
    }
}
.app-map_bg-brown {
    background-color: brown;
}
.app-map__sidemenu_70 {
    height: 70%;
}
.app-map__sidemenu_50 {
    height: 50%;
}
.app-map__sidemenu_60 {
    height: 60%;
}
</style>
