<template>
  <div
    id="order-map"
    class="container-fluid h-100 p-0"
  >
    <!-- Desktop -->
    <template v-if="!isMobile">
      <div class="row w-100 h-100">
        <div class="col-3 d-flex flex-column app-map-menu-section">
          <div class="row pl-1 pr-1">
            <h1>Карта заказов</h1>
          </div>
          <map-menu
            @update:array="getArray"
            :instance="mapInstance"
          ></map-menu>
        </div>
        <div class="col-9 h-100 w-100 p-0">
          <y-map
            :array="array"
            @map-instance="getMapInstance"
          ></y-map>
        </div>
      </div>
    </template>
    <!-- Desktop -->
    <!-- Mobile -->
    <template v-else>
      <div class="row h-100">
        <div class="col-12 h-100 p-0 w-100">
          <b-tabs content-class="h-100">
            <b-tab
              title="Меню"
              active
            >
              <map-menu
                @update:array="getArray"
                :instance="mapInstance"
              ></map-menu>
            </b-tab>
            <b-tab
              title="Карта"
              @click="mapKey++"
            >
              <y-map
                :key="mapKey"
                :array="array"
                @map-instance="getMapInstance"
              ></y-map>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </template>
    <!-- Mobile -->
  </div>
</template>

<script>
import YMap from './Map';
import MapMenu from './MapMenu';

export default {
  name: 'OrderMap',
  components: {
    YMap,
    MapMenu,
  },
  data: function() {
    return {
      baseCoords: [],
      array: [],
      mapInstance: null,
      mapKey: 0,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 1024 ? true : false
    },
  },
  methods: {
    getArray({ array }) {
      if (array.length) {
        this.array = array
      }
    },
    getMapInstance(instance) {
      this.mapInstance = instance
    },
  },
};
</script>

<style lang="scss" scoped>
.ymap-container {
    @media screen and (max-width: 980px) {
        height: calc(100vh - 145px) !important;
    }
}
.nav-tabs {
    margin-bottom: 0.5rem !important;
}

.mh-100vh {
  max-height: 100vh;
}

.app-map-menu-section {
  overflow: hidden;
}
</style>
