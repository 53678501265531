var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('yandex-map',{staticClass:"map",attrs:{"coords":_vm.coords,"zoom":10},on:{"map-was-initialized":_vm.mapInstance}},[_vm._l((_vm.points),function(point,i){return [_c('ymap-marker',{key:new Date().getTime() + i,attrs:{"coords":point.coords,"hint-content":((point.courier_name ? point.courier_name : 'Не назначен') + "<br />" + (point.address)),"marker-id":point.order_id,"options":{
              iconCaption: '',
              iconContent: '' ,
              preset: point.active ? 'islands#redIcon' : point.courier_id ? _vm.presets[point.courier_color] : _vm.presets['#B3B3B3']
          },"properties":{
              iconContent: point.order,
              iconCaption: point.content
          },"marker-type":"Placemark"}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }